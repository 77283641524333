@tailwind base;
@tailwind components;
@tailwind utilities;

#pesticide-chrome-result {
	display: none;
}

p,
span {
	font-family: 'Inter', sans-serif;
	color: #000;

	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.pageParentDiv {
	background-color: white;
	height: 100%;
	width: 100%;
	padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 15px;
	padding-right: 15px;
}

@media (max-width: 768px) {
	.pageParentDiv {
		padding: 5px;
	}
}

.pageChildrenDiv {
	background-color: white;
	height: 100%;
	width: 100%;
}

.my-scrollable-div {



	overflow-y: scroll;

}

.my-scrollable-div::-webkit-scrollbar {
	width: 15px;

}

.my-scrollable-div::-webkit-scrollbar-track {
	background-color: rgb(217, 217, 217);
	border-radius: 20px;
	/* Here is the change */
}

.my-scrollable-div::-webkit-scrollbar-thumb {
	background-color: rgb(204, 49, 27);
	border-radius: 10px;
}

.my-scrollable-div::-webkit-scrollbar-thumb:hover {
	background-color: rgb(204, 49, 27);

}

.horizontal-container {
	display: flex;
	gap: 0.75rem;
}

.vertical-container {
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
}

.input-warning {
	border: 2px solid #CC311B;
	/* background-color: #CC311B; */
}

.msg-warning {
	font-style: italic;
	color: red;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

html {
	scroll-behavior: smooth;
  }