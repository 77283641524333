.amap-marker-label {
    background-color: white;
    border: 1px solid #ccc;
    z-index:  2;
    padding: 3px;

    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    
    line-height: 14px;

}

.marker-label {
    background-color: white;
    border: 1px solid #ccc;
    z-index:  1000;
    padding: 3px;

    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    
    line-height: 14px;

}

#container {
    z-index: 10; /* You can set this to any value that works for your layout */
    position: relative; /* z-index won't work without setting the position */
  }
