

.marker-label {
    width:150px;
    background-color: white;
    border: 1px solid #ccc;
    z-index:  2;
    padding: 3px;

    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    
    line-height: 14px;

}